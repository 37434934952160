import React from 'react'
import _ from 'lodash'

import { AboutBlock } from '../Blocks'

import audit1 from '../../assets/images/bug/bug.png'


const BugOne = ({ lang }) => {
  const isEn = lang === 'en';

  const AboutBlocks = [
    {
      rootProps: { className: 'tile row' },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: audit1, loading: 'eager' },
      textGroup: {
        rootProps: { className: 'text-group col-6 col-12-medium' },
        title: {
          type: 'h1',
          text:
            isEn ? 'EN' : 'Está a enfrentar problemas com o seu website?',
        },
        description: {
          text:
            isEn ? "EN" : "O nosso serviço de suporte personalizado é a chave para resolver problemas, otimizar desempenho e elevar a sua presença online ao máximo potencial.",
        },
        cta: {
            rootProps: {
              className: 'button audit-btn audit-anchor-link gethelp-one',
              to: isEn ? '#' : '#',
            },
            text: isEn ? "Let's talk" : 'Obtenha a nossa ajuda',
        },
      },
    },
  ]

  return (
    <section id="about-two">
      <div className="content container">
        <div className="row">
          {_.map(AboutBlocks, (block, i) => (
            <React.Fragment key={`about-block-${i}`}>
              <AboutBlock rev={i % 2} {...block} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
export default BugOne
