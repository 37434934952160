import React from 'react'
import _ from 'lodash'

import introimg from '../../assets/images/audit/intro/suport-intro.png'

import TextGroup, { Image } from '../Texts'

const BugIntro = ({ lang }) => {
  const isEn = lang === 'en'

  return (
    <section id="performance-intro" className="bg-white bg-gray lp-intro">
      <div className="content container">
        <div className="row">
            <div className="tile row">
                <div className="col-6 col-12-medium image image-margin-right">
                <Image image={introimg} loading="lazy" />
                </div>
                <div className="text-group col-6 col-12-medium">
                    <h2>O nosso Suporte Especializado</h2>
                    <p>Na log, entendemos a importância de manter o seu website WordPress a funcionar sem problemas para garantir o sucesso online contínuo do seu negócio, mantendo-o no pico do desempenho.</p>
                    <ul>
                        <li>Oferecemos um suporte abrangente</li>
                        <li>Mantemos o seu website WordPress e resolvemos problemas, permitindo-lhe focar-se no crescimento do seu negócio com total tranquilidade.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
export default BugIntro
