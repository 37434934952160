import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import { AboutBlock } from '../Blocks'

import audit1 from '../../assets/images/audit/consultant2.png'

const BugFour = ({ lang }) => {
  const isEn = lang === 'en';
  
  const AboutBlocks = [
    {
      rootProps: { className: 'tile row' },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: audit1, loading: 'lazy' },
      textGroup: {
        rootProps: { className: 'text-group col-6 col-12-medium' },
        title: {
          type: 'h2',
          text:
            isEn ? 'EN' : 'Eleve a sua presença digital!',
        },
        description: {
          text:
            isEn ? "EN" : "Na log, criamos experiências digitais de excelência. Seja através da otimização de SEO, do fortalecimento da segurança ou da aceleração da performance, o sucesso do seu negócio online é a nossa dedicação. Descubra como ainda hoje.",
        },
        cta: {
            rootProps: {
              className: 'button audit-btn audit-anchor-link gethelp-three',
              to: isEn ? '#' : '#',
            },
            text: isEn ? "AgendarEN" : 'Obtenha já a nossa ajuda',
        },
      },
    },
  ]

  return (
    <section id="audit-four" className="bg-blue">
      <div className="content container">
        <div className="row">
          {_.map(AboutBlocks, (block, i) => (
            <React.Fragment key={`about-block-${i}`}>
              <AboutBlock rev={i % 2} {...block} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
};

export default BugFour;
