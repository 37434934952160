import React, { useEffect, useState } from 'react';
import TextGroup, { CTA } from '../Texts'

import audit2 from '../../assets/images/audit/audit-2.png'
import ServiceModal from "../ServiceModal";
import close_icon from '../../assets/images/close.svg'

const BugTwo = ({ lang }) => {
  const isEn = lang === 'en';
  const [clickedTileIndexes, setClickedTileIndexes] = useState([]);

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleTileClick = (index) => {

    setClickedTileIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((id) => id !== index);
      } else {
        return [...prevIndexes, index];
      }
    });

    //show modal
    setModalVisible(true);
    // set modal data on click
    setModalData(sliderTiles[index]);
  };
    

   // hide modal on close.
   const onModalCloseHandler = () => {
    setModalVisible(false);
    setClickedTileIndexes([]);
  };
    

  const sliderTiles = [
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Instalação e Configuração do WordPress' : 'Instalação e Configuração do WordPress',
      },
      modal: {
        title:"Instalação e Configuração do WordPress",
        description: "Configuramos o seu website WordPress para uma base sólida, garantindo uma presença online eficaz.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Segurança WordPress' : 'Segurança WordPress',
      },
      modal: {
        title:"Segurança WordPress",
        description: "Protegemos o seu website WordPress com medidas sólidas, assegurando a integridade dos seus dados e a confiança dos visitantes.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Resolução de Problemas WordPress' : 'Resolução de Problemas WordPress',
      },
      modal: {
        title:"Resolução de Problemas WordPress",
        description: "Resolvemos qualquer obstáculo, mantendo o seu website a funcionar de forma eficaz e sem contratempos.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Testes Funcionais WordPress' : 'Testes Funcionais WordPress',
      },
      modal: {
        title:"Testes Funcionais WordPress",
        description: "Testamos minuciosamente para garantir que todas as funcionalidades do seu website WordPress operam sem falhas.",
      }
    },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Resolução de Bugs' : 'Resolução de Bugs',
        },
        modal: {
          title:"Resolução de Bugs",
          description: "Identificamos e corrigimos bugs, garantindo uma experiência de utilizador livre de problemas.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Atualizações e Correções de Software' : 'Atualizações e Correções de Software',
        },
        modal: {
          title:"Atualizações e Correções de Software",
          description: "Mantemos o seu software atualizado e corrigido, assegurando um desempenho otimizado e segurança constante.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'E-commerce' : 'E-commerce',
        },
       
        modal: {
          title:"E-commerce",
          description: "Impulsionamos o seu e-commerce, maximizando a eficiência e proporcionando uma experiência de compra sem atritos.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Otimização de Desempenho' : 'Otimização de Desempenho',
        },
       
        modal: {
          title:"Otimização de Desempenho",
          description: "Otimizamos o desempenho do seu website, garantindo velocidade e eficácia para atrair e manter os visitantes.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Backup e Restauração' : 'Backup e Restauração',
        },
       
        modal: {
          title:"Backup e Restauração",
          description: "Salvaguardamos os seus dados com backups regulares, garantindo uma rápida recuperação em caso de imprevistos.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Gestão de Utilizadores WordPress' : 'Gestão de Utilizadores WordPress',
        },
       
        modal: {
          title:"Gestão de Utilizadores WordPress",
          description: "Gerimos eficazmente utilizadores, garantindo um acesso adequado e mantendo a segurança.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Gestão de Temas e Plugins' : 'Gestão de Temas e Plugins',
        },
       
        modal: {
          title:"Gestão de Temas e Plugins",
          description: "Supervisionamos temas e plugins, garantindo uma harmonia perfeita e evitando conflitos.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Multilinguismo' : 'Multilinguismo',
        },
       
        modal: {
          title:"Multilinguismo",
          description: "Expandimos a sua presença global, oferecendo um suporte multilingue para que possa alcançar audiências diversificadas.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Auditoria SEO' : 'Auditoria SEO',
        },
       
        modal: {
          title:"Auditoria SEO",
          description: "Avaliamos a sua presença online, otimizando para os motores de busca e impulsionando a visibilidade do seu website WordPress.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Testes de Desempenho' : 'Testes de Desempenho',
        },
       
        modal: {
          title:"Testes de Desempenho",
          description: "Avaliamos a sua presença online, otimizando para os motores de busca e impulsionando a visibilidade do seu website WordPress.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Desenvolvimento de Plugins e Temas Personalizados' : 'Desenvolvimento de Plugins e Temas Personalizados',
        },
       
        modal: {
          title:"Desenvolvimento de Plugins e Temas Personalizados",
          description: "Criamos soluções exclusivas para as suas necessidades específicas, elevando a sua presença online.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Correção de Erros de Programação' : 'Correção de Erros de Programação',
        },
       
        modal: {
          title:"Correção de Erros de Programação",
          description: "Identificamos e corrigimos erros de programação, assegurando um código preciso e funcional.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Problemas de Compatibilidade WordPress' : 'Problemas de Compatibilidade WordPress',
        },
       
        modal: {
          title:"Problemas de Compatibilidade WordPress",
          description: "Resolvemos problemas de compatibilidade, garantindo que todas as partes do seu website WordPress funcionam harmoniosamente.",
        }
      },
  ]

  return (
    <section id="services-two" className="bg-white services lp-tiles audit-two">
      <div className="content container">
        <div className="col-12 slider-wrapper">
          <TextGroup
            rootProps={{
              className: 'col-12 heading-large',
            }}
            title={{
              type: 'h2',
              text: isEn
                ? 'We work with you as one team.'
                : 'Serviços log suporte:',
            }}
          />
          <div className="audit2-cnt">
            <div className="row tiles-row row-relative">
              {sliderTiles.map((tile, i) => (
                <div
                  key={`services-column-${i}`}
                  className={`col-4 col-12-medium tile-column ${
                    clickedTileIndexes.includes(i) ? 'clicked' : ''
                  }`}
                  onClick={() => handleTileClick(i)}
                >
                  <TextGroup {...tile} />
                </div>
              ))}
            </div>
            <div className="cta cta-audit">
              <CTA
                rootProps={{
                  className: 'button primary audit-anchor-link gethelp-two',
                  to: isEn ? '#' : '#',
                }}
                text={isEn ? "Let's talk" : 'Obtenha a nossa ajuda'}
              />
            </div>
          </div>
        </div>
      </div>

      <ServiceModal
        visible={modalVisible}
        title={modalData?.modal?.title}
        description={modalData?.modal?.description}
      >
        <img 
          src={close_icon} 
          alt="Close" 
          className="service-modal__close"
          onClick={onModalCloseHandler}
        />
          <a 
            href="#" 
            className="button audit-btn audit-anchor-link gethelp-box"
            onClick={onModalCloseHandler}
          >
           {isEn ? "Schedule a Free Consultation" : 'Obtenha a nossa ajuda'}
          </a>
        </ServiceModal>
    </section>
  )
}

export default BugTwo
