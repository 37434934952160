import React from 'react'
import TextGroup from '../Texts'
import _ from 'lodash'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module

// Front End
import yoast from '../../assets/images/audit/plugins/yoast.png';
import wpml from '../../assets/images/audit/plugins/wpml.png';
import wprocket from '../../assets/images/audit/plugins/wp-rocket.png';

const main_plugins = [
	{
		image: yoast,
		title: 'Yoast',
	},
    {
		image: wpml,
		title: 'WPML',
	},
    {
		image: wprocket,
		title: 'WP Rocket',
	},
    {
		image: yoast,
		title: 'Yoast',
	},
    {
		image: wpml,
		title: 'WPML',
	},
    {
		image: wprocket,
		title: 'WP Rocket',
	},
]

const breakpoints = {
    768: {
      slidesPerView: 3,
    },
    0: {
      slidesPerView: 'auto',
    },
  };

const BugPlugins = ({ lang }) => {
	const isEn = lang === 'en';
	return (
        <section id="audit-plugins" className="bg-white bg-gray audit-five">
            <div className="container content main-services">
                <TextGroup
                title={{
                    type: 'h2',
                    text: isEn ? 'Plugins' : 'Plugins',
                }}
                />
                <p class="p-sub">A nossa prioridade é oferecer um suporte de excelência. Utilizamos uma seleção cuidadosa de plugins fundamentais para o melhor funcionamento do seu website WordPress.</p>
                
                <div className="row">
                    <div className="col-12 swiper-plugins">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={10}
                            navigation
                            observer={true}
                            observeParents={true}
                            observeSlideChildren={true}
                            resizeObserver={true}
                            rebuildonupdate={true}
                            className='swiper-plugins-slider'
                            breakpoints={breakpoints}
                        >
                            {_.map(main_plugins, (plugin, i) => (
                                <SwiperSlide className='tech-slide active'>
                                    <div className='technology-item'>
                                        <div className="plugin-item">
                                            <img src={plugin.image} alt={plugin.title} title={plugin.title} loading="lazy" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
	)
}
export default BugPlugins