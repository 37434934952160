import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import { AboutBlock } from '../Blocks'
import TextGroup, { CTA } from '../Texts'

const PerformanceSix = ({ lang }) => {
  const isEn = lang === 'en';

  const [showMeetingContainer] = useState(false);

  useEffect(() => {
    const loadScripts = () => {
      // Load the first script
      const script1 = document.createElement('script');
      script1.type = 'text/javascript';
      script1.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script1.async = true;
      document.body.appendChild(script1);

      // Load the second script
      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WRFFKK57');

        window.addEventListener('message', function(event) {
          if (event.data.meetingBookSucceeded) {
            window.dataLayer.push({
              event: 'hubspot-meeting-booked'
            });
          }
          if (
            (event.origin === 'https://meetings.hubspot.com' ||
             event.origin === 'https://meetings-eu1.hubspot.com') &&
            event.data.meetingBookSucceeded
          ) {
            window.dataLayer.push({
              event: 'hubspot_meeting_booked'
            });
          }
        });
      `;
      document.body.appendChild(script2);
    };

    // Load scripts after the page has fully loaded
    document.addEventListener('DOMContentLoaded', loadScripts);

    // Cleanup function
    return () => {
      // Remove the first script
      const script1 = document.querySelector('script[src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"]');
      script1 && document.body.removeChild(script1);

      // Remove the second script
      const script2 = document.querySelector('script[data-layer="GTM-WRFFKK57"]');
      script2 && document.body.removeChild(script2);
    };
  }, []);
  
  return (
    <section id="audit-four" className="bg-blue">
      <div className="content container">
        <div className="row">
          <div className="col-12">
            <TextGroup
              rootProps={{
                className: 'col-12 heading-large align-center',
              }}
              title={{
                type: 'h2',
                text: isEn
                  ? 'EN'
                  : 'Juntos, elevamos o seu sucesso digital',
              }}
            />
            <div className="cta cta-audit cta-center">
                <CTA
                  rootProps={{
                    className: 'button audit-btn audit-anchor-link talktous-form',
                  }}
                  text={isEn ? "EN" : 'Contacte-nos agora'}
                />
            </div>
          </div>
        </div>
      </div>
      <div className="meetings-iframe-container bg-white hidden" data-src="https://meetings-eu1.hubspot.com/diogo-graca?embed=true">
      </div>
    </section>
  )
};

export default PerformanceSix;
